<template>
<div>
    <b-row>

        <b-col md="12">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LAS INCIDENCIAS DE SOFTWARE
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col md="3" sm="6" class="my-2 icon-custom vr">
                            <i style="inline-size: auto" class="ico fas fa-laptop fa-3x"></i>
                            <br>
                            <span class="lg-numero">{{listaIncidenciasSoftware.length}}</span>
                            <br>
                            <span class="text-muted">Total registradas</span>
                        </b-col>
                        <b-col md="3" class="my-2 icon-custom vr">
                            <i style="inline-size: auto" class="ico far fa-flag fa-3x"></i> <br>
                            <span class="lg-numero">{{listaIncidenciasSoftware.filter(a=>a.estado==1).length}}</span>
                            <br>
                            <span class="text-muted">Pendientes</span>
                        </b-col>
                        <b-col md="3" class="my-2 icon-custom vr">
                            <i style="inline-size: auto" class="ico far fa-clock fa-3x"></i> <br>
                            <span class="lg-numero">{{listaIncidenciasSoftware.filter(a=>a.estado==2).length}}</span>
                            <br>
                            <span class="text-muted">En desarrollo</span>
                        </b-col>
                        <b-col md="3" class="my-2 icon-custom">
                            <i style="inline-size: auto" class="ico far fa-check-square fa-3x"></i> <br>
                            <span class="lg-numero">{{listaIncidenciasSoftware.filter(a=>a.estado==3).length}}</span>
                            <br>
                            <span class="text-muted">Finalizadas</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <!-- <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de incidencias de software </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="4" class="my-2 ">
                            <b-button block size="md" variant="custom" @click="modalNuevoColaborador = true">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Nuevo colaborador
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col> -->

        <b-col md="12">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Incidencias de software registradas</span>
                </CCardHeader>

                <CCardBody>
                    <div>
                        <b-row>
                            <b-col lg="2">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="7">
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table :items="listaIncidenciasSoftware" :fields="campoIncidenciasSoftware" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" bordered hover show-empty mediun responsive outlined @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template #cell(estado)="data">
                                        <h5 v-if="data.value=='1'">
                                            <b-badge variant="danger">
                                                <i style="inline-size: auto" class="ico far fa-flag fa-sm"></i> Pendiente
                                            </b-badge>
                                        </h5>
                                        <h5 v-if="data.value=='2'">
                                            <b-badge variant="info">
                                                <i style="inline-size: auto" class="ico far fa-clock fa-sm"></i> En desarrollo
                                            </b-badge>
                                        </h5>
                                        <h5 v-if="data.value=='3'">
                                            <b-badge variant="success">
                                                <i style="inline-size: auto" class="ico far fa-check-square fa-sm"></i> Finalizada
                                            </b-badge>
                                        </h5>
                                    </template>
                                    <template #cell(acciones)="param">
                                        <b-button @click="abrirModalGestionarIncidencia(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-button>
                                        <!-- <b-button @click="eliminarColaborador(param, 1)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i> 
                                        </b-button> -->
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </div>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" size="lg" color="dark" :show.sync="modalGestionarIncidencia">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Gestión de incidencia de software </span>
            </h6>
            <CButtonClose @click="modalGestionarIncidencia = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarIncidenciaSoftware)">
                <b-row>
                    <b-col md="4">
                        <b-form-group label="Tipo de tarea">
                            <b-form-input disabled class="disabled-white" v-model="datosIncidencia.tipoTarea"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group label="Fecha de notificación">
                            <b-form-input disabled class="disabled-white" v-model="datosIncidencia.fechaNotificacion"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group label="Fecha prevista de resolución">
                            <b-form-input disabled class="disabled-white" v-model="datosIncidencia.fechaPrevistaResolucion"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="estado" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Estado:" class="mb-2">
                                <b-form-select v-model="datosIncidencia.estado" :options="comboEstado" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha de resolución" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de resolución:" class="mb-2">
                                <b-form-input v-model="datosIncidencia.fechaResolucion" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Descripción de la incidencia">
                            <b-form-textarea disabled class="disabled-white" rows="3" max-rows="6" v-model="datosIncidencia.incidencia"></b-form-textarea>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="comentario del programador" :rules="{ }" v-slot="validationContext">
                            <b-form-group label="Comentario del programador:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" placeholder="Ingrese el comentario" v-model="datosIncidencia.comentarioProgramador" :state="getValidationState(validationContext)"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" class="mt-2">
                        <span>Documentos: </span>
                        <li class="fa-ul mt-2" v-for="documento in datosIncidencia.listaDocumentos" :key="documento.index">
                            <span @click="descargarDocumento(documento.urlDocumento)" class="text-muted h6" style="cursor:pointer">
                                <i class="fas fa-download"></i> {{documento.nombreDocumento}}
                            </span>
                        </li>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalGestionarIncidencia = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalGestionarIncidencia: false,
            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoIncidenciasSoftware: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "cliente",
                    label: "Cliente",
                    class: "text-center",
                },
                {
                    key: "fechaNotificacion",
                    label: "Fecha de notificación",
                    class: "text-center",
                },
                {
                    key: "fechaPrevistaResolucion",
                    label: "Fecha prevista de resolución",
                    class: "text-center",
                },
                {
                    key: "fechaResolucion",
                    label: "Fecha de resolución",
                    class: "text-center",
                },
                // {
                //     key: "incidencia",
                //     label: "Descripción",
                //     class: "text-center",
                // },
                // {
                //     key: "comentarioProgramador",
                //     label: "Comentario",
                //     class: "text-center",
                // },
                {
                    key: "estado",
                    label: "Estado",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            campoDocumentos: [{
                key: "documento",
                label: "Documentos",
                class: "text-center"
            }, ],
            comboEstado: [{
                    value: '1',
                    text: 'Pendiente'
                }, {
                    value: '2',
                    text: 'En desarrollo'
                },
                {
                    value: '3',
                    text: 'Finalizada'
                }
            ],
            listaIncidenciasSoftware: [],
            datosIncidencia: {
                idIncidenciaSoftware: '',
                idCliente: '',
                tipoTarea: null,
                incidencia: '',
                fechaNotificacion: '',
                fechaPrevistaResolucion: '',
                fechaResolucion: moment().format('YYYY-MM-DD'),
                comentarioProgramador: null,
                estado: null,
                listaDocumentos: [],
            },
            disabled: false,
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        descargarDocumento(url) {
            window.open(url)
        },
        listarIncidenciasSoftware() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/listar-incidencias-software", {
                    params: {

                    }
                })
                .then(function (response) {
                    me.listaIncidenciasSoftware = response.data;
                    me.totalRows = me.listaIncidenciasSoftware.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarIncidenciasSoftwareDocumentos(idIncidenciaSoftware) {
            let me = this;
            me.datosIncidencia.listaDocumentos.splice(0, me.datosIncidencia.listaDocumentos.length);
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/listar-incidencias-software-documentos", {
                    params: {
                        idIncidenciaSoftware: idIncidenciaSoftware
                    }
                })
                .then(function (response) {
                    for (const i in response.data) {
                        me.datosIncidencia.listaDocumentos.push({
                            urlDocumento: response.data[i].urlDocumento,
                            nombreDocumento: response.data[i].nombreDocumento,
                        })
                    }

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        abrirModalGestionarIncidencia(param) {
            let me = this;
            me.datosIncidencia.idIncidenciaSoftware = param.item.idIncidenciaSoftware;
            me.datosIncidencia.idCliente = param.item.idCliente;
            me.datosIncidencia.fechaNotificacion = param.item.fechaNotificacion;
            me.datosIncidencia.fechaPrevistaResolucion = param.item.fechaPrevistaResolucion;
            me.datosIncidencia.tipoTarea = param.item.tipoTarea == 1 ? 'Modificación/Mejora' : 'Error de funcionamiento';
            me.datosIncidencia.incidencia = param.item.incidencia;
            me.datosIncidencia.estado = param.item.estado;
            me.datosIncidencia.comentarioProgramador = param.item.comentarioProgramador;
            me.datosIncidencia.fechaResolucion = param.item.fechaResolucion ? param.item.fechaResolucion : moment().format('YYYY-MM-DD');
            me.listarIncidenciasSoftwareDocumentos(param.item.idIncidenciaSoftware)
            me.modalGestionarIncidencia = true;

        },
        registrarIncidenciaSoftware() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-incidencia-software",
                    me.datosIncidencia, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalGestionarIncidencia = false;
                    me.listarIncidenciasSoftware();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        resetModalGestionarIncidencia() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });

        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {

    },
    watch: {
        modalGestionarIncidencia: function (val) {
            if (val == false) {
                this.resetModalGestionarIncidencia();
            }
        }
    },
    mounted() {
        this.listarIncidenciasSoftware();
    }

}
</script>
